.completed-task .MuiDataGrid-cell {
  text-decoration: line-through;
  color: red;
}

/* gamePage.css */
.max-width-container {
  width: 100%;
  max-width: 90%;
  margin: 0 auto;
}

.selected-column {
  background-color: #2962ff;
  /* Change the background color as desired */
  color: white;
  /* Change the text color as desired */
  border: none;
}

/* delete button style */
.icon-red-on-hover {
  cursor: pointer;
  color: inherit;
  transition: color 0.3s;
}

.icon-red-on-hover:hover {
  color: red;
}



.col-main-style {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px;
  font-size: 15px;
  font-weight: 800;
  height: 50px;
  cursor: pointer;
  padding: 0 10px;
}


.col-main-style>div {
  display: flex;
  align-items: center;
}

.col-child-text-style {
  display: inline;
  text-align: left;
}

.col-child-text-style>p:first-child {
  font-size: 13px;
  color: #797979;
}


/* CustomRewards.css */
.main-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
}

.main-div-child1 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.custom-each {
  flex: 1;
  padding: 0 20px;
}

.custom-each-child {
  display: flex;
  justify-content: space-between;
}

/* ViralMarketing.css */
.badge-success {
  background-color: green;
}

.badge-info {
  background-color: blue;
}

.badge-warning {
  background-color: yellow;
}

.badge-secondary {
  background-color: gray;
}