.billing-tab {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}

.billing-tab .form-check {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.billing-tab .form-check-input {
  border-radius: 2rem !important;
  width: 2rem;
  height: 2rem;
}

.billing-tab .btn-close {
  border: 2px solid red;
  border-radius: 2rem;
  padding: 0.5rem;
  /* background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='red'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") */
}

.billing-tab .mainBillingParent {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #ececec;
  border-radius: 1rem;
  padding: 0.5rem;
}

.billingAccordion {
  width: 100%;
  text-align: left;
}
.billingAccordion .accordion-button:not(.collapsed) {
  background-color: transparent;
}

@media screen and (max-width: 768px) {
  .billing-tab .mainBillingParent {
    flex-direction: column;
    align-items: baseline;
    gap: 1rem;
  }
}